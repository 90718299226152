import React from 'react';
import $ from 'jquery';

import {Button} from 'primereact/button';
// import {Column} from 'primereact/column';
// import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';
import {RadioButton} from 'primereact/radiobutton';
// import {Checkbox} from 'primereact/checkbox';
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import validator from 'validator';
import {Welcome} from './Welcome';
import {RTLForm} from './RTLForm';
import {baselinePatientElements} from "./BaselinePatient";
import {monthlyFollowUpElements} from "./MonthlyFollowUp";
//import PhoneInput from 'react-phone-number-input';


function ValidationMessage(props) {
    if (!props.valid) {
        return(
          <div className='error-msg'>{props.message}</div>
        )
    }
    return null;
}

function RadioYesNoWrapper(props) {
    let valid = true;
    let message = '';
    // console.log(props);
    if (props.object.state[props.formData].validate !== undefined) {
        valid = props.object.state[props.formData].validate[props.fieldName].valid;
        message = props.object.state[props.formData].errorMsg[props.fieldName];
    }
    return (
        <div>
            <ValidationMessage
                valid={valid}
                message={message} />
            <label className={"response"}>
                <RadioButton className={"p-radiobutton"}
                             value="1"
                             onChange={props.object.onFieldChange(props.formData, props.fieldName)}
                             checked={props.object.getValue(props.formData, props.fieldName) === '1'} /> Yes
            </label>
            <label className={"response"}>
                <RadioButton className={"p-radiobutton"}
                             value="0"
                             onChange={props.object.onFieldChange(props.formData, props.fieldName)}
                             checked={props.object.getValue(props.formData, props.fieldName) === '0'} /> No
            </label>
        </div>
    );
}

function InputTextWrapper(props) {
    return (
      <div>
          <ValidationMessage
                valid={props.object.state[props.formData].validate[props.fieldName].valid}
                message={props.object.state[props.formData].errorMsg[props.fieldName]} />
          <InputText value={props.object.getValue(props.formData, props.fieldName)}
                     onChange={props.object.onFieldChange(props.formData, props.fieldName)} />
      </div>
    )
}


export class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            // showSubmit: true,
            view: 'Welcome',
            screeningViewVisible: false,
            registrationFormVisible: false,
            registrationFormData: {},
            inclusionExclusionFormVisible: false,
            inclusionExclusionFormData: {},
            baselinePatientFormVisible: false,
            baselineCoordinatorFormVisible: false,
            monthlyFollowUpFormVisible: false,
            rowID: 0
        };
    }

    componentDidMount() {
        // console.log('Executing componentDidMount.');
        $.post("./api/get_user.php", {}, function(response) {
            // This is temporary code that is in place until the production database is ready.
            // When ready, remove the code and uncomment out the rest of the code.
            this.setState({
                isLoaded: true,
                sites: []
            });
            // try {
            //     if (typeof response !== 'object' || response === null) {
            //         console.log(response);
            //         alert('API call failed.');
            //     }
            //     else if (response.status === 0) {
            //         this.setState({
            //             isLoaded: true,
            //             sites: response.data.sites
            //         });
            //     }
            //     else {
            //         this.error_alert(response.error_message);
            //     }
            // }
            // catch (err) {
            //     alert('Error occurred: ' + err);
            // }
        }.bind(this), "json");
    }

    render() {
        if (this.state.isLoaded) {
            switch (this.state.view) {
                case 'Get Started':
                    let siteOptions = this.state.sites.map(function(value) {
                        return {label: value.site_name, value: value.site_name};
                    });
                    siteOptions.push({label: 'Other', value: 'Other'});
                    // console.log(siteOptions);
                    return (
                        <div className={"visitorBodyContent"}>
                            <img src="./WIsDoM-logo.png" alt="WIsDoM Logo" />
                            <div className={"midVisitorBodyContent"}>
                                <div className={"title"}>
                                    Welcome to the <span style={{color: "#f285b2"}}>Women with IBD and Motherhood (WIsDoM) Study Page</span>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    Please complete the information below and a research coordinator will contact you to provide more information about the research study and see if you are eligible to participate.
                                </div>
                            </div>
                            <p>* Denotes a required field</p>
                            <table>
                                <tr>
                                    <td className={"question"}>Are you a woman between 18 - 45 years old?*</td>
                                    <td>
                                        <RadioYesNoWrapper
                                            formData={'registrationFormData'}
                                            fieldName={'is_woman_between_18_and_45'}
                                            object={this}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}>Have you been diagnosed with Inflammatory Bowel Disease (IBD)?*</td>
                                    <td>
                                        <RadioYesNoWrapper
                                            formData={'registrationFormData'}
                                            fieldName={'has_ibd_diagnosis'}
                                            object={this}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}>Are you currently pregnant?*</td>
                                    <td>
                                        <RadioYesNoWrapper
                                            formData={'registrationFormData'}
                                            fieldName={'is_currently_pregnant'}
                                            object={this}
                                        />
                                    </td>
                                </tr>
                                {this.getValue('registrationFormData', 'is_currently_pregnant') === '0' &&
                                <tr>
                                    <td className={"question"}>Are you currently trying to get pregnant?*</td>
                                    <td>
                                        <RadioYesNoWrapper
                                            formData={'registrationFormData'}
                                            fieldName={'is_trying_to_get_pregnant'}
                                            object={this}
                                        />
                                    </td>
                                </tr>
                                }
                                {this.getValue('registrationFormData', 'is_currently_pregnant') === '0' &&
                                        this.getValue('registrationFormData', 'is_trying_to_get_pregnant') === '0' &&
                                <tr>
                                    <td className={"question"}>Are you planning to become pregnant within the next 18 months?*</td>
                                    <td>
                                        <RadioYesNoWrapper
                                            formData={'registrationFormData'}
                                            fieldName={'is_planning_pregnancy'}
                                            object={this}
                                        />
                                    </td>
                                </tr>
                                }
                                <tr>
                                    <td className={"question"}>How did you hear about us?*</td>
                                    <td>
                                        <ValidationMessage valid={this.state.registrationFormData.validate.how_heard.valid} message={this.state.registrationFormData.errorMsg.how_heard} />
                                        <Dropdown
                                                value={this.getValue('registrationFormData', 'how_heard')}
                                                options={siteOptions}
                                                placeholde="Select..."
                                                onChange={this.onFieldChange('registrationFormData', 'how_heard')} />
                                        {this.getValue('registrationFormData', 'how_heard') === 'Other' &&
                                        <label className={"response"}>
                                            - specify: <InputText placeholder="Other Source" value={this.getValue('registrationFormData', 'other_source')} onChange={this.onFieldChange('registrationFormData', 'other_source')} />
                                        </label>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}>First name*:</td>
                                    <td>
                                        <InputTextWrapper
                                            formData={'registrationFormData'}
                                            fieldName={'first_name'}
                                            object={this}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}>Last name*:</td>
                                    <td>
                                        <InputTextWrapper
                                            formData={'registrationFormData'}
                                            fieldName={'last_name'}
                                            object={this}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}>Primary phone*:</td>
                                    <td>
                                      <ValidationMessage valid={this.state.registrationFormData.validate.telephone.valid} message={this.state.registrationFormData.errorMsg.telephone} />
                                      <InputText placeholder="start with area code" value={this.getValue('registrationFormData', 'telephone')} onChange={this.onPhoneChange('registrationFormData', 'telephone')} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}>Email address*:</td>
                                    <td>
                                        <InputTextWrapper
                                            formData={'registrationFormData'}
                                            fieldName={'email'}
                                            object={this}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}>Preferred mode of contact*:</td>
                                    <td>
                                        <ValidationMessage valid={this.state.registrationFormData.validate.preferred_mode_of_contact.valid} message={this.state.registrationFormData.errorMsg.preferred_mode_of_contact} />
                                        <label className={"response"}><RadioButton className={"p-radiobutton"} value="Email" onChange={this.onFieldChange('registrationFormData', 'preferred_mode_of_contact')} checked={this.getValue('registrationFormData', 'preferred_mode_of_contact') === 'Email'} /> Email</label>
                                        <label className={"response"}><RadioButton className={"p-radiobutton"} value="Phone" onChange={this.onFieldChange('registrationFormData', 'preferred_mode_of_contact')} checked={this.getValue('registrationFormData', 'preferred_mode_of_contact') === 'Phone'} /> Phone</label>
                                    </td>
                                </tr>
                            </table>
                            <p><Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Submit" onClick={this.onClickSubmitSelfRegistration} disabled={!this.state.registrationFormData.formValid} /></p>
                        </div>
                    );
                    // break;
                case 'Email Exists':
                    return (
                        <div className={"visitorBodyContent"}>
                            <img src="./WIsDoM-logo.png" alt="WIsDoM Logo" />
                            <div className="title">
                                Thank you again for your interest in the WIsDoM study!
                            </div>
                            <div style={{textAlign: "left"}}>
                                <p>
                                    Someone has already registered with the email address you provided. You may contact us at:
                                </p>
                                <p>
                                    Principal Investigator ({this.state.registrant.site_name}): {this.state.registrant.principal_investigator}
                                    <br />
                                    Study Coordinator ({this.state.registrant.site_name}): {this.state.registrant.study_coordinator}
                                    <br />
                                    Telephone: {this.state.registrant.site_telephone}
                                    <br />
                                    E-mail: {this.state.registrant.site_email}
                                </p>
                            </div>
                        </div>
                    );
                    // break;
                case 'Thank You':
                    return (
                        <div className={"visitorBodyContent"}>
                            <img src="./WIsDoM-logo.png" alt="WIsDoM Logo" />
                            <div className="title">
                                Thank you again for your interest in the WIsDoM study!
                            </div>
                            <div style={{textAlign: "left"}}>
                                {this.isEligible() &&
                                <p>
                                    We have sent confirmation of your registration via email. If you did not receive the message, please check your spam folder, and add us to your contact list and/or de-activate the spam blocker for our email address. Someone from {this.state.registrant.site_name} will reach out to you within a week with further explanation and next steps. You may also contact us at:
                                </p>
                                }
                                {!this.isEligible() &&
                                <p>
                                    Based on your answers, you do not qualify to be a participant at this time. If you have questions about why you are ineligible, you can reach us at:
                                </p>
                                }
                                <p>
                                    Principal Investigator ({this.state.registrant.site_name}): {this.state.registrant.principal_investigator}
                                    <br />
                                    Study Coordinator ({this.state.registrant.site_name}): {this.state.registrant.study_coordinator}
                                    <br />
                                    Telephone: {this.state.registrant.site_telephone}
                                    <br />
                                    E-mail: {this.state.registrant.site_email}
                                </p>
                                <p>
                                    Would you like to be contacted for our other studies regarding IBD?
                                    <br />
                                    <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Yes" onClick={this.onClickOtherInterest('Yes')} />
                                    <span style={{paddingLeft: "15px"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="No" onClick={this.onClickOtherInterest('No')} />
                                    </span>
                                </p>
                            </div>
                        </div>
                    );
                    // break;
                case 'Interest':
                    // console.log(this.isEligible());
                    // console.log(this.state.is_interested_in_other_studies);
                    return (
                        <div className={"visitorBodyContent"}>
                            <img src="./WIsDoM-logo.png" alt="WIsDoM Logo" />
                            <div className="title">
                                Thank you for your response.
                            </div>
                            {!this.isEligible() && this.state.is_interested_in_other_studies === 'Yes' &&
                            <div style={{textAlign: "left"}}>
                                We have sent confirmation of your registration via email. If you did not receive the message, please check your spam folder, and add us to your contact list and/or de-activate the spam blocker for our email address. We will save your contact information, and a member of our study team will contact you when we have other studies regarding IBD (Inflammatory Bowel Disease) alone or IBD and Pregnancy/Motherhood.
                            </div>
                            }
                        </div>
                    );
                    // break;
                case 'Questionnaire Due':
                    return (
                        <div className={"visitorBodyContent"}>
                            <img src="./WIsDoM-logo.png" alt="WIsDoM Logo" />
                            <div className={"midVisitorBodyContent"}>
                                <div className={"title"}>
                                    Welcome to the <span style={{color: "#f285b2"}}>Women with IBD and Motherhood (WIsDoM) Study Page</span>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    Your questionnaire is ready.
                                </div>
                            </div>
                            <p><Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Start questionnaire" onClick={this.onClickStartQuestionnaire} /></p>
                            {this.state.baselinePatientFormVisible &&
                            <Dialog
                                    header="Baseline Patient"
                                    visible={this.state.baselinePatientFormVisible}
                                    onHide={this.onHide('baselinePatientFormVisible')}
                                    closable={true}
                                    contentStyle={{maxHeight: 700, overflow: 'scroll'}}
                                    baseZIndex={4}>
                                <RTLForm 
                                        elements={baselinePatientElements}
                                        onSubmit={this.processBaselinePatient} 
                                        data={{email_address: this.state.email_address, access_key: this.state.access_key}} />
                            </Dialog>
                            }
                            {this.state.monthlyFollowUpFormVisible &&
                            <Dialog
                                    header="Monthly Follow-Up"
                                    visible={this.state.monthlyFollowUpFormVisible}
                                    onHide={this.onHide('monthlyFollowUpFormVisible')}
                                    closable={true}
                                    contentStyle={{maxHeight: 700, overflow: 'scroll'}}
                                    baseZIndex={5}>
                                <RTLForm 
                                        elements={monthlyFollowUpElements}
                                        onSubmit={this.processMonthlyFollowUp} 
                                        data={{email_address: this.state.email_address, access_key: this.state.access_key}} />
                            </Dialog>
                            }
                        </div>
                    );
                    // break;
                case 'Questionnaire Done':
                    return (
                        <div className={"visitorBodyContent"}>
                            <img src="./WIsDoM-logo.png" alt="WIsDoM Logo" />
                            <div className={"midVisitorBodyContent"}>
                                <div className={"title"}>
                                    Welcome to the <span style={{color: "#f285b2"}}>Women with IBD and Motherhood (WIsDoM) Study Page</span>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    Thank you for completing the questionnaire. You will receive email when your next questionnaire is due.
                                </div>
                            </div>
                        </div>
                    );
                    // break;
                case 'Questionnaire Not Due':
                    return (
                        <div className={"visitorBodyContent"}>
                            <img src="./WIsDoM-logo.png" alt="WIsDoM Logo" />
                            <div className={"midVisitorBodyContent"}>
                                <div className={"title"}>
                                    Welcome to the <span style={{color: "#f285b2"}}>Women with IBD and Motherhood (WIsDoM) Study Page</span>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    You do not have a questionnaire that is due at this time. You will receive email when your next questionnaire is due.
                                </div>
                            </div>
                        </div>
                    );
                    // break;
                default:
                    return (
                        <Welcome object={this} />
                    );
            }
        }
        else {
            return (<div>Loading...</div>);
        }
    }

    error_alert = (error_message) => {
        if (error_message.indexOf('ERROR:') === 0) {
            error_message = error_message.substr(7);
            if (error_message.indexOf('CONTEXT:') > -1) {
                error_message = error_message.substr(0, error_message.indexOf('CONTEXT:'));
            }
        }
        alert(error_message);
    }

    getValue = (dataSource, fieldName) => {
        let record = this.state[dataSource];
        if (record === null || record === undefined || record[fieldName] === null || record[fieldName] === undefined ) {
            return '';
        }

        return record[fieldName];
    }

    isEligible = () => {
//        console.log(this.state);
        return (this.state.registrant.is_woman_between_18_and_45 === '1' && this.state.registrant.has_ibd_diagnosis === '1'
                && this.state.registrant.is_currently_pregnant === '0' && (this.state.registrant.is_trying_to_get_pregnant === '1' || this.state.registrant.is_planning_pregnancy === '1'));
    }

    onClickBaselinePatientForm = (e) => {
        this.setState({
            baselinePatientFormVisible: true
        });
    }

    // onClickCaptcha = (response) => {
    //     // this.setState({showSubmit: true})
    //     var formData = Object.assign({}, this.state.registrationFormData);
    //     if (formData.hasOwnProperty('validate') && formData.validate.hasOwnProperty('recaptcha')) {
    //         // POST to the back-end to verify
    //         console.log(response);
    //         $.post("./api/verify_captcha.php", {captchaResponse: response}, function (response) {
    //             try {
    //                 if (typeof response !== 'object' || response === null) {
    //                     console.log(response);
    //                     alert('API call failed.');
    //                 }
    //                 else if (response.status === 0) {
    //                     formData.validate.recaptcha.valid = true;
    //                 } else {
    //                     formData.validate.recaptcha.valid = false;
    //                 }
    //                 this.setState({
    //                     registrationFormData: formData
    //                 }, this.validateForm);
    //             } catch (err) {
    //                 alert('Error occurred: ' + err);
    //             }
    //         }.bind(this), "json");
    //     }
    // }

    onClickGetStarted = (e) => {
        this.setState({
            view: 'Get Started',
            registrationFormData: {
                validate: {
                    is_woman_between_18_and_45: {
                        valid: false
                    },
                    has_ibd_diagnosis: {
                        valid: false
                    },
                    is_currently_pregnant: {
                        valid: false,
                        child: 'is_trying_to_get_pregnant'
                    },
                    is_trying_to_get_pregnant: {
                        valid: true,
                        parent: 'is_currently_pregnant',
                        parent_check_value: '0'
                    },
                    is_planning_pregnancy: {
                        valid: true,
                        parent: 'is_trying_to_get_pregnant',
                        parent_check_value: '0'
                    },
                    how_heard: {
                        valid: false
                    },
                    first_name: {
                        valid: false
                    },
                    last_name: {
                        valid: false
                    },
                    email: {
                        valid: false,
                        check: 'email'
                    },
                    telephone: {
                        valid: false,
                        check: 'phone'
                    },
                    preferred_mode_of_contact: {
                        valid: false
                    }
                },
                formValid: false,
                errorMsg: {}
            }
        });
    }

    onClickLogin = (data) => (e) => {
        console.log(data);
        $.post("./api/get_questionnaire.php", data, function(response) {
            console.log(response);
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    if (response.survey_sequence === -1) {
                        this.setState({
                            view: 'Questionnaire Not Due'
                        });
                    }
                    else {
                        this.setState({
                            view: 'Questionnaire Due',
                            survey_sequence: response.survey_sequence,
                            email_address: data.email_address,
                            access_key: data.access_key
                        });
                    }
                }
                else {
                    this.error_alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    onClickMonthlyFollowUpForm = (e) => {
        this.setState({
            monthlyFollowUpFormVisible: true
        });
    }

    onClickOtherInterest = (answer) => (e) => {
        e.preventDefault(); // TODO: Check this

        $.post("./api/save_interest.php", {id: this.state.registrant.id, is_interested_in_other_studies: answer}, function(response) {
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    this.setState({
                        view: 'Interest',
                        is_interested_in_other_studies: answer
                    });
                }
                else {
                    this.error_alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    onClickStartQuestionnaire = (e) => {
        if (this.state.survey_sequence === '0') {
            this.setState({
                baselinePatientFormVisible: true
            });
        }
        else {
            this.setState({
                monthlyFollowUpFormVisible: true
            });
        }
    }

    processBaselinePatient = (formData) => {
        console.log(formData);
        $.post("./api/save_baseline_patient.php", formData, function(response) {
      //      console.log(response);
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    this.setState({
                        view: 'Questionnaire Done',
                        baselinePatientFormVisible: false,
                        monthlyFollowUpFormVisible: false
                    });
                }
                else {
                    this.error_alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    processMonthlyFollowUp = (formData) => {
        console.log(formData);
        $.post("./api/save_monthly_followup.php", formData, function(response) {
            console.log(response);
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    this.setState({
                        view: 'Questionnaire Done',
                        baselinePatientFormVisible: false,
                        monthlyFollowUpFormVisible: false
                    });
                }
                else {
                    this.error_alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    onClickSubmitSelfRegistration = (e) => {
        let formData = this.state.registrationFormData;
        let postData = {
            is_woman_between_18_and_45: formData.is_woman_between_18_and_45,
            has_ibd_diagnosis: formData.has_ibd_diagnosis,
            is_currently_pregnant: formData.is_currently_pregnant,
            is_trying_to_get_pregnant: (formData.is_currently_pregnant === '0' ? formData.is_trying_to_get_pregnant : null),
            is_planning_pregnancy: (formData.is_currently_pregnant === '0' && formData.is_trying_to_get_pregnant === '0' ? formData.is_planning_pregnancy : null),
            how_heard: formData.how_heard,
            other_source: (formData.how_heard === 'Other' ? formData.other_source : null),
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            telephone: formData.telephone,
            preferred_mode_of_contact: formData.preferred_mode_of_contact
        };
        this.saveRegistrant(postData, 'Thank You');
    }

    checkCheckbox = (dataSource, fieldName) => (e) => {
        var formData = Object.assign({}, this.state[dataSource]);
        formData[fieldName] = e.target.checked;
        this.validateField(formData, fieldName, e);
        this.setState({[dataSource]: formData}, this.validateForm);
    }

    isPrimaryTemplate = (rowData, column) => {
        return rowData.is_primary ? 'Yes': 'No';
    }

    onFieldChange = (dataSource, fieldName) => (e) => {
        var formData = Object.assign({}, this.state[dataSource]);
        formData[fieldName] = e.target.value;
        this.validateField(formData, fieldName, e);
        this.setState({[dataSource]: formData}, this.validateForm);
    }

    onPhoneChange = (dataSource, fieldName) => (e) => {
            var formData = Object.assign({}, this.state[dataSource]);
            e.target.value = (e.target.value).substring(0,13);
     //       console.log("the e.target.value: " + e.target.value);
            formData[fieldName] = this.formatPhoneNumber(e.target.value);
            this.validateField(formData, fieldName, e);
            this.setState({[dataSource]: formData}, this.validateForm);
    }

    formatPhoneNumber = (value) => {
      //  console.log(value);
        if (!value) return value;
        const phoneNumber = (value.replace(/[^\d]/g, "")).substring(0,10);
      //  const phoneNumber = value.replace(/[^\d]/g, "");
    //    console.log("phoneNumber input before format: " + phoneNumber);
        const phoneNumberLength = phoneNumber.length;
    //    console.log("phone number length: " + phoneNumberLength);
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return '(' + phoneNumber.slice(0,3) + ')' + phoneNumber.slice(3);
        }
        return '(' + phoneNumber.slice(0,3) + ')' + phoneNumber.slice(3,6) + '-' + phoneNumber.slice(6,10);
    }

    validateField = (formData, fieldName, e) => {
        if (formData.hasOwnProperty('validate') && formData.validate.hasOwnProperty(fieldName)) {
            if (!formData.validate[fieldName].hasOwnProperty('parent') ||
              (formData.validate[fieldName].hasOwnProperty('parent') &&
                formData[formData.validate[fieldName].parent] === formData.validate[fieldName].parent_check_value)) {
                formData.validate[fieldName].valid = e.target.value !== ''
                if (e.target.value === '') {
                    formData.errorMsg[fieldName] = '';
         //           formData.errorMsg[fieldName] = 'Required';
                } else if (formData.validate[fieldName].hasOwnProperty('check')) {
                    switch (formData.validate[fieldName].check) {
                        case "email":
                            if (!validator.isEmail(formData[fieldName])) {
                                formData.errorMsg[fieldName] = 'Enter a valid email address';
                                formData.validate[fieldName].valid = false;
                            } else {
                                formData.validate[fieldName].valid = true;
                            }
                            break;
                        case "phone":
                            if(!(formData[fieldName].match(/\d/g).length === 10)) {
                                formData.errorMsg[fieldName] = 'Enter 10 digits number';
                                formData.validate[fieldName].valid = false;
                            } else {
                                formData.validate[fieldName].valid = true;
                            }
                            break;
                        default:
                    }
                }
            } else {
                formData.validate[fieldName].valid = true;
            }
            if (formData.validate[fieldName].hasOwnProperty('child')) {
                this.validateChildField(formData, fieldName);
            }
        }
    }
    
    validateChildField = (formData, parentFieldName) => {
        if (formData[parentFieldName] === formData.validate[formData.validate[parentFieldName].child].parent_check_value
          && (!formData.hasOwnProperty(formData.validate[parentFieldName].child)
            || formData[formData.validate[parentFieldName].child] === '')) {
            formData.validate[formData.validate[parentFieldName].child].valid = false;
             formData.errorMsg[formData.validate[parentFieldName].child] = ' ';
           //  formData.errorMsg[formData.validate[parentFieldName].child] = 'Required';
        } else {
            formData.validate[formData.validate[parentFieldName].child].valid = true;
        }
    }

    validateForm = () => {
        var formData = Object.assign({}, this.state.registrationFormData);
        if(formData.hasOwnProperty('validate')) {
            formData.formValid = Object.keys(formData.validate).every(function(k){ return formData.validate[k].valid });
//            console.log(formData.validate)
        }
        this.setState({
            registrationFormData: formData
        });
    }

    onHide = (prop) => (e) => {
        this.setState({[prop]: false});
    }

    radioButtonResponse = (source, field_name, field_value) => {
        return (
            <label className={"response"}>
                <RadioButton className={"p-radiobutton"}
                            value={field_value}
                            onChange={this.onFieldChange(source, field_name)}
                            checked={this.getValue(source, field_name) === field_value} /> {field_value}
            </label>

        );
    }

    saveRegistrant = (postData, view) => {
        console.log(postData);
        $.post("./api/save_registrant.php", postData, function(response) {
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    if (response.email_exists === 'Yes') {
                        view = 'Email Exists';
                    }
                    this.setState({
                        view: view,
                        registrant: response.data
                    });
                }
                else {
                    alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }
}
